<template>
    <nuxt-link v-if="locale === config.locale" :to="link" :class="$attrs.class">
        <slot></slot>
    </nuxt-link>
    <a v-else-if="locale === 'external'" :href="link" target="_blank" rel="noopener" :class="$attrs.class">
        <slot></slot>
    </a>
    <a v-else :href="(locale === '_' || $locales[locale] === undefined ? homeLink : $locales[locale].url) + link" :class="$attrs.class">
        <slot></slot>
    </a>
</template>

<script>
import useConfig from '../composables/useConfig.ts';

export default {
    props: {
        link: { required: true },
        locale: { required: true },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
    data() {
        return {
            homeLink: this.$config.public.home,
        };
    },
};
</script>
